import axios from "axios";
import { config } from "../../config";
// const queryString = require('query-string')

export class UserService {
  public updateUserInfo = async (user: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + "users/me", user);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public addUser = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + `users`, data);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public importUsers = async (form: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + `users/import-users`, form);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public updateUser = async (id: number, data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + `users/${id}`, data);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public addNewUser = async (data: any, tenantId: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + `users/add-user-to-tenant?tenantId=${tenantId}`,
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public activate = async (id: any, data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        `${serverUrl}users/${id}/activate`,
        data
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  public addNewUserToKeyCloak = async (
    data: any,
    tenantId: any
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + `users/add-user-to-keycloak?tenantId=${tenantId}`,
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public verifyNewUser = async (data: any, tenantId: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "auth/verify-new-user?tenantId=" + tenantId,
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public resetPassword = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "auth/reset-password",
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public changePassword = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "auth/reset-password",
        data
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public addNewUserToCommonDb = async (
    data: any,
    tenantId: any
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "users/add-user?tenantId=" + tenantId,
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public getUserTenants = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "auth/me/tenants");
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public login = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + "auth/login", data);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public logout = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + "auth/logout", data);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public sendUserForgottenPasswordEmail = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "auth/send-user-forgotten-password-email",
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public canResetForgottenPassword = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "auth/can-reset-forgotten-password",
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public checkTokenValidity = async (
    data: any,
    tenantId: any
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "auth/can-set-password?tenantId=" + tenantId,
        data
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
}
