import "./add-new-user.scoped.scss";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import { emailRegex } from "../../statics/validators";
import { UserService } from "../../services/user/user.service";
import { showToastMsg } from "../../helpers";
const subscriptionPlans: number[] = [6, 12, 24, 36];

interface AddNewUserModalProps {
  show: boolean;
  onClose: (value: boolean) => void;
  user?: any;
  editable?: boolean;
  reactivate?: boolean;
}

const AddNewUserModal = (props: AddNewUserModalProps) => {
  const { user, show, onClose, editable, reactivate } = props;
  const [t] = useTranslation();
  const userService: UserService = new UserService();

  const [firstName, setFirstName] = useState(editable ? user.firstName : "");
  const [lastName, setLastName] = useState(editable ? user.lastName : "");
  const [email, setEmail] = useState(editable ? user.email : "");
  const [role, setRole] = useState(
    editable ? (user.type === "superUser" ? user.type : user.role) : ""
  );
  const [group, setGroup] = useState(editable ? user.group : "");
  const [company, setCompnay] = useState(editable ? user.company : "");
  const [plan, setPlan] = useState(
    editable ? (user.plan ? user.plan : "") : ""
  );

  const [saving, setSaving] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [focused, setFocused] = useState(false);
  const [defaultPasswordChecked, setDefaultPasswordChecked] = useState(false);

  const submit = async () => {
    const data: any = {
      firstName: capitalize(firstName),
      lastName: capitalize(lastName),
      email,
      role,
      group,
      company,
      plan: plan ? plan : null,
      defPassChecked: defaultPasswordChecked,
    };

    // const res = await usersService.activate(row.id);
    //     if (res && res.message === "activated") {
    //       refresh();
    //       showToastMsg("success", t("user activated successfully"));
    // }

    const res = editable
      ? reactivate
        ? await userService.activate(user.id, {
            role: data.role,
            plan: data.plan,
          })
        : await userService.updateUser(user.id, { role: data.role })
      : await userService.addUser({ users: [data] });

    if (res && res.users) {
      if (res && res.users.length && res.users[0].message === "SUCCESS") {
        showToastMsg("success", t("USER_ADD_SUCCESSFULLY"));
      } else if (
        res &&
        res.users.length &&
        res.users[0].message === "EXISTING"
      ) {
        showToastMsg("error", t("User exists already"));
      } else {
        showToastMsg("error", t("System error, please try again later"));
      }
    } else if (reactivate && res && res.message === "ACTIVATED") {
      showToastMsg("success", t("user activated successfully"));
    } else {
      showToastMsg("error", t("SOME_THING_WENT_WRONG"));
    }
  };

  const onSubmit = async () => {
    if (saving) return;
    setSaving(true);
    await submit();
    setSaving(false);
    handleClose(true);
  };

  const changeRole = (value: any) => {
    setRole(value);
    if (value === "superUser") {
      setDefaultPasswordChecked(false);
      setGroup("internal");
    } else if (value === "auditor") {
      setPlan("");
    }
  };

  const handleClose = (value: any) => {
    onClose(value);
    // reset state
    setDefaultPasswordChecked(false);
    setGroup("");
    setEmailError(false);
    setEmail("");
    setLastName("");
    setFirstName("");
    setRole("");
    setPlan("");
    setFocused(false);
  };

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const onFocus = (input: HTMLInputElement | null) => {
    if (input && !focused) {
      timeoutRef.current = setTimeout(() => {
        input.focus();
        setFocused(true);
      }, 1);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose.bind(null, false)}
      centered
      dialogClassName="modal-new-user"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("invite new user")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box className="row" style={{ rowGap: "1rem" }}>
          <Box className="col-6">
            <TextField
              fullWidth
              label={t("firstName")}
              onChange={(e: any) => setFirstName(e.target.value.trim())}
              value={firstName}
              name="first name"
              variant={"filled"}
              inputRef={onFocus}
              disabled={editable}
              inputProps={{
                maxLength: 30,
              }}
            />
          </Box>
          <Box className="col-6">
            <TextField
              fullWidth
              label={t("lastName")}
              onChange={(e: any) => setLastName(e.target.value.trim())}
              value={lastName}
              name="last name"
              variant={"filled"}
              disabled={editable}
              inputProps={{
                maxLength: 30,
              }}
            />
          </Box>

          <Box className="col-12">
            <TextField
              fullWidth
              label="Email"
              onChange={(e: any) => {
                setEmail(e.target.value);
                e.target.value
                  ? setEmailError(!emailRegex.test(e.target.value))
                  : setEmailError(false);
              }}
              value={email}
              name="email"
              variant={"filled"}
              type="email"
              disabled={editable}
              error={emailError ? true : false}
            />
            {emailError && (
              <Box className="error-msg ">{t("invalid-email")}</Box>
            )}
          </Box>

          <Box className="col-6">
            <FormControl className="select-input select-grey w-100">
              <InputLabel id="role">{t("role")}</InputLabel>
              <Select
                labelId="role"
                name="role"
                value={role}
                onChange={(e: any) => {
                  changeRole(e.target.value);
                }}
                IconComponent={ExpandMoreIcon}
                disabled={editable && user.type === "superUser"}
              >
                <MenuItem
                  value={"superUser"}
                  disabled={editable && user.type !== "superUser"}
                >
                  {t("coordinator")}
                </MenuItem>
                <MenuItem value={"editor"}>{t("EDITOR")}</MenuItem>
                <MenuItem value={"auditor"}>{t("AUDITOR")}</MenuItem>
                <MenuItem value={"contributor"}>{t("USER")}</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box className="col-6">
            <FormControl
              className={
                "select-input w-100 " +
                (role !== "superUser" ? "select-grey" : "")
              }
            >
              <InputLabel
                id="internal/external"
                className={role === "superUser" ? "disableLabel" : ""}
              >
                {t("internal/external")}
              </InputLabel>
              <Select
                labelId="internal/external"
                name="internal/external"
                value={group}
                onChange={(e: any) => setGroup(e.target.value)}
                IconComponent={ExpandMoreIcon}
                disabled={role === "superUser" || editable}
              >
                <MenuItem value={"internal"}>{t("internal")}</MenuItem>
                <MenuItem value={"external"}>{t("external")}</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box className="col-6">
            <TextField
              fullWidth
              label={t("COMPANY")}
              onChange={(e: any) => setCompnay(e.target.value)}
              value={company}
              name="compnay"
              variant={"filled"}
              disabled={editable}
              // inputProps={{
              //   maxLength: 30,
              // }}
            />
          </Box>

          <Box className="col-6">
            <FormControl
              className={
                "select-input w-100 " +
                (role !== "auditor" ? "select-grey" : "")
              }
            >
              <InputLabel id="subscription">{t("SUBSCRIPTION")}</InputLabel>
              <Select
                labelId="subscription"
                name="subscription"
                value={plan}
                onChange={(e: any) => setPlan(e.target.value)}
                IconComponent={ExpandMoreIcon}
                disabled={role === "auditor" || (!!user && !reactivate)}
              >
                {subscriptionPlans.map((item: any, index: number) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item} {t("MONTHS")}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          {!editable && (
            <Box className="col-12 mx-2">
              <FormControlLabel
                className="checkbox-global"
                control={
                  <Checkbox
                    checked={defaultPasswordChecked}
                    onChange={() =>
                      setDefaultPasswordChecked(!defaultPasswordChecked)
                    }
                    name="jaChecked"
                    size="small"
                    disabled={role === "superUser"}
                  />
                }
                label={t("set default password")}
              />
            </Box>
          )}
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <>
          <Button
            variant="outlined"
            color="primary"
            className="btn-secondary-theme"
            onClick={handleClose.bind(null, false)}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="btn-primary-theme"
            onClick={onSubmit}
            disabled={
              emailError ||
              firstName.length === 0 ||
              lastName.length === 0 ||
              group.length === 0 ||
              email.length === 0 ||
              role.length === 0 ||
              saving ||
              (editable && user.role === role && !reactivate) ||
              (reactivate && plan.length === 0 && role !== "auditor") ||
              (plan.length === 0 && !editable && role !== "auditor")
            }
          >
            {saving ? (
              <CircularProgress color="inherit" />
            ) : (
              <>
                <CheckIcon />{" "}
                {editable
                  ? reactivate
                    ? t("reactivate")
                    : t("EDIT_ROLE")
                  : t("ADD_USER")}
              </>
            )}
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewUserModal;
